import { useScanImages } from '@hooks/useScanImages';
import { Box, CircularProgress, ImageList, ImageListItem, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

export const ImageGrid = ({
  setSelectedImageId,
  selectedImageId,
}: {
  setSelectedImageId: (selectedImageId: string) => void;
  selectedImageId: string | null;
}) => {
  const { scanId } = useParams();

  const { data, isLoading } = useScanImages({ scanId });

  if (isLoading) {
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!data) {
    return <Typography>Bilder konnten nicht geladen werden</Typography>;
  }

  const { images } = data;

  if (images.length <= 0) {
    return <Typography>Keine Bilder für diesen Scan</Typography>;
  }

  return (
    <ImageList sx={{ width: '100%' }} cols={2}>
      {images.map(({ id, downloadUrl }) => (
        <ImageListItem
          key={id}
          onClick={() => setSelectedImageId(id)}
          sx={{
            aspectRatio: '1/1',
            opacity: id === selectedImageId ? 0.5 : 1,
            transition: 'opacity 0.2s',
          }}
        >
          <img src={downloadUrl} alt="Raw Scan" loading="lazy" />
        </ImageListItem>
      ))}
    </ImageList>
  );
};
