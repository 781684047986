import { ArrowLeft, ArrowRight } from '@deepup/icons';
import { Box, IconButton, type SxProps, Typography } from '@mui/material';
import { type ReactNode, useEffect } from 'react';
import { useId, useState } from 'react';

import { useThemeMode } from '@hooks/useThemeMode';

const Slide = ({ children, label }: { children: ReactNode; label: string }) => {
  return (
    <Box aria-label={label} aria-roledescription="slide" component="div" role="group">
      {children}
    </Box>
  );
};

export const ImageCarousel = ({
  slides,
  label,
  sx,
  sxDisplay,
  sxControls,
  index,
  onSlideChange,
}: {
  slides: ReactNode[];
  label: string;
  sx?: SxProps;
  sxDisplay?: SxProps;
  sxControls?: SxProps;
  index?: number;
  onSlideChange?: (index: number) => void;
}) => {
  const [currentSlide, setCurrentSlide] = useState(index ?? 0);
  const id = useId();
  const { isDarkMode } = useThemeMode();

  useEffect(() => {
    if (typeof index !== 'undefined') {
      setCurrentSlide(index > slides.length - 1 ? slides.length - 1 : index);
    }
  }, [index, slides.length]);

  return (
    <Box aria-label={label} aria-roledescription="carousel" component="section" sx={sx}>
      <Box component="div" sx={{ position: 'relative', height: '100%' }}>
        <Box
          component="div"
          sx={{
            position: 'absolute',
            top: '30px',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            pointerEvents: 'none',
            ...sxDisplay,
          }}
        >
          <Typography
            component="span"
            sx={{
              padding: '5px 10px',
              background: isDarkMode ? 'rgba(0, 0, 0, 0.7)' : 'rgba(220, 220, 220, 0.7)',
              borderRadius: '5px',
              color: isDarkMode ? '#fff' : '#000',
            }}
          >
            {currentSlide + 1} of {slides.length}
          </Typography>
        </Box>
        <Box
          component="div"
          sx={{
            position: 'absolute',
            zIndex: 1,
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            left: '20px',
            right: '20px',
            pointerEvents: 'none',
            ...sxControls,
          }}
        >
          <IconButton
            aria-controls={id}
            aria-label={'Previous Image'}
            onClick={() =>
              setCurrentSlide((prevState) => {
                const index = (prevState > 0 ? prevState : slides.length) - 1;

                onSlideChange?.(index);

                return index;
              })
            }
            sx={{
              background: isDarkMode ? 'rgba(0, 0, 0, 0.7)' : 'rgba(220, 220, 220, 0.7)',
              '&:hover': {
                background: isDarkMode ? 'rgba(0, 0, 0, 0.3)' : 'rgba(200, 200, 200, 0.7)',
              },
              pointerEvents: 'auto',
            }}
          >
            <ArrowLeft fill={isDarkMode ? '#fff' : '#000'} />
          </IconButton>
          <IconButton
            aria-controls={id}
            aria-label={'Next Image'}
            onClick={() =>
              setCurrentSlide((prevState) => {
                const index = (prevState + 1) % slides.length;

                onSlideChange?.(index);

                return index;
              })
            }
            sx={{
              background: isDarkMode ? 'rgba(0, 0, 0, 0.7)' : 'rgba(220, 220, 220, 0.7)',
              '&:hover': {
                background: isDarkMode ? 'rgba(0, 0, 0, 0.3)' : 'rgba(200, 200, 200, 0.7)',
              },
              pointerEvents: 'auto',
            }}
          >
            <ArrowRight fill={isDarkMode ? '#fff' : '#000'} />
          </IconButton>
        </Box>
        <Box component="div" sx={{ overflow: 'hidden', height: '100%' }}>
          <Box
            aria-live="polite"
            component="div"
            id={id}
            sx={{
              display: 'flex',
              width: `${slides.length * 100}%`,
              height: '100%',
              transform: `translate(-${currentSlide * (100 / slides.length)}%)`,
              transition: 'transform 0.5s',
            }}
          >
            {slides.map((slide, index) => (
              <Slide key={index} label={`${index + 1} von ${slides.length}`}>
                {slide}
              </Slide>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
