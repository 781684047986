import { Box, useMediaQuery, useTheme, Paper } from '@mui/material';
import { type ReactNode } from 'react';

export type SidebarProps = {
  children: ReactNode;
  isFullHeight?: boolean;
  isFullWidth?: boolean;
};

export const Sidebar = ({ children, isFullHeight, isFullWidth }: SidebarProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const width = isMobile ? undefined : '350px';

  return (
    <Paper
      elevation={3}
      sx={{
        width: isFullWidth ? undefined : width,
        position: 'absolute',
        right: '10px',
        left: isMobile || isFullWidth ? '10px' : undefined,
        top: { xs: 'calc(56px + 10px)', sm: 'calc(80px + 10px)' },
        bottom: isFullHeight ? '10px' : undefined,
        zIndex: 1190,
      }}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'stretch',
        }}
      >
        <Box component="div" sx={{ display: 'flex', flex: 1, overflowY: 'auto', padding: '0' }}>
          {children}
        </Box>
      </Box>
    </Paper>
  );
};
