import { AppGrid2X2Outline, CubeOutline } from '@deepup/icons';
import { Measurement, Viewer3DHelp } from '@deepup/viewer3d';
import { Box, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { ErrorBoundary } from '@components/ErrorBoundary';
import { ErrorPage } from '@components/ErrorPage';
import { ImageGrid } from '@components/Images/ImageGrid';
import { ImageViewer } from '@components/Images/ImageViewer';
import { MeshViewer } from '@components/MeshViewer';
import { Sidebar } from '@components/Sidebar';
import { Tabs } from '@components/Tabs';
import { useState } from 'react';

export const Page = () => {
  const [isImageTabOpen, setIsImageTabOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState<null | string>(null);
  const { scanId } = useParams();

  if (!scanId) {
    return <ErrorPage errorText="ScanId is missing" />;
  }

  return (
    <>
      <Measurement
        translations={{
          width: 'Breite',
          depth: 'Tiefe',
          step: 'Schritt',
          depthHelperText: 'Klicke auf den Grubenboden und die Oberkante, um die Tiefe zu messen.',
          widthHelperText: 'Klicke auf die linke und rechte Oberkante, um die Breite zu messen.',
          guideAnimationURL: (state, mode) =>
            `https://storage.googleapis.com/webassets-deepup-production/animations/${state}_guide_de_${mode}.mp4`,
        }}
      >
        {({ measurementView, measurementControls, onCanvasClick }) => (
          <>
            <ErrorBoundary fallback={<ErrorPage errorText="Error while loading 3D model" />}>
              <MeshViewer
                scanId={scanId}
                onCanvasClick={onCanvasClick}
                measurementView={measurementView}
              />
            </ErrorBoundary>
            <Sidebar
              isFullHeight={isImageTabOpen}
              isFullWidth={isImageTabOpen && !!selectedImageId}
            >
              {!!selectedImageId && (
                <ImageViewer
                  selectedImageId={selectedImageId}
                  setSelectedImageId={setSelectedImageId}
                />
              )}
              <Box minWidth="350px" maxWidth="350px">
                <Tabs
                  isFullHeight={isImageTabOpen}
                  onChange={(index) => {
                    const imageTabIndex = 1;
                    setIsImageTabOpen(index === imageTabIndex);
                    if (index !== imageTabIndex) {
                      setSelectedImageId(null);
                    }
                  }}
                  items={[
                    {
                      label: (
                        <>
                          <Box
                            component="div"
                            sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}
                          >
                            <CubeOutline fill="currentColor" height="1.4em" width="1.4em" />
                            <Typography>3D Ansicht</Typography>
                          </Box>
                        </>
                      ),
                      children: (
                        <Stack direction="row" justifyContent="space-between">
                          {measurementControls}
                          <Viewer3DHelp
                            translations={{
                              heading: 'Bedienung',
                              moveLabel: 'Ver-schieben',
                              moveChip: 'linke Maustaste',
                              zoomLabel: 'Zoomen',
                              zoomChip: 'Mausrad',
                              rotateLabel: 'Rotieren',
                              rotateChip: 'rechte Maustaste',
                            }}
                          />
                        </Stack>
                      ),
                    },
                    {
                      label: (
                        <Box
                          component="div"
                          sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}
                        >
                          <AppGrid2X2Outline fill="currentColor" height="1.4em" width="1.4em" />
                          <Typography>Roh-Bilder</Typography>
                        </Box>
                      ),
                      children: (
                        <ImageGrid
                          setSelectedImageId={setSelectedImageId}
                          selectedImageId={selectedImageId}
                        />
                      ),
                    },
                  ]}
                />
              </Box>
            </Sidebar>
          </>
        )}
      </Measurement>
    </>
  );
};
