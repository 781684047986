import { XOutline } from '@deepup/icons';
import { useScanImages } from '@hooks/useScanImages';
import { Box, Button, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ImageCarousel } from './ImageCarousel';
import { ImageZoom } from './ImageZoom';

export const ImageViewer = ({
  selectedImageId,
  setSelectedImageId,
}: {
  selectedImageId: string;
  setSelectedImageId: (selectedImageId: string | null) => void;
}) => {
  const { scanId } = useParams();
  const { data } = useScanImages({ scanId });
  const images = data?.images ?? [];

  const selectedImage = images.find((img) => img.id === selectedImageId);

  return (
    <Box sx={{ borderRight: 1, borderColor: 'divider', width: '100%', height: '100%' }}>
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'divider',
          height: 48,
          paddingX: 2,
        }}
      >
        <Typography>{selectedImageId}</Typography>
        <Button endIcon={<XOutline />} onClick={() => setSelectedImageId(null)}>
          Detailansicht schließen
        </Button>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" height="85%" paddingTop={2}>
        {!selectedImage && <Typography>Image not found</Typography>}
        {selectedImage && (
          <ImageCarousel
            sx={{ height: '100%', width: '100%' }}
            index={images.indexOf(selectedImage)}
            onSlideChange={(index) => {
              const slideImage = images.at(index);
              if (slideImage) {
                setSelectedImageId(slideImage.id);
              }
            }}
            slides={images.map((img) => (
              <ImageZoom
                key={img.id}
                image={
                  <img
                    key={img.id}
                    src={img.downloadUrl}
                    alt=""
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                }
              />
            ))}
            label={'Raw Images'}
          />
        )}
      </Box>
    </Box>
  );
};
