export const getBuildEnvironment = () => {
  return {
    auth0Audience: import.meta.env?.VITE_AUTH0_AUDIENCE,
    auth0ClientId: import.meta.env?.VITE_AUTH0_CLIENT_ID,
    auth0Domain: import.meta.env?.VITE_AUTH0_DOMAIN,
    apiTrenchviewer: import.meta.env?.VITE_API_TRENCHVIEWER,
    apiUnified: import.meta.env?.VITE_API_URL,
    sentryDsn: import.meta.env?.VITE_SENTRY_DSN,
    sentryAuthToken: import.meta.env?.VITE_SENTRY_AUTH_TOKEN,
    appVersion: import.meta.env?.VITE_APP_VERSION,
    dev: import.meta.env?.DEV,
    mode: import.meta.env?.MODE,
  };
};

export const getRuntimeEnvironment = () => {
  const env = window.__env__ || {};

  const envVars = {
    auth0Audience: env?.VITE_AUTH0_AUDIENCE,
    auth0ClientId: env?.VITE_AUTH0_CLIENT_ID,
    auth0Domain: env?.VITE_AUTH0_DOMAIN,
    apiTrenchviewer: env?.VITE_API_TRENCHVIEWER,
    apiUnified: env?.VITE_API_URL,
    sentryDsn: env?.VITE_SENTRY_DSN,
    sentryAuthToken: env?.VITE_SENTRY_AUTH_TOKEN,
    appVersion: env?.VITE_APP_VERSION,
    dev: env?.DEV,
    mode: env?.MODE,
  };

  // Filter out entries with undefined values
  const definedVars = Object.fromEntries(
    Object.entries(envVars).filter(([, value]) => value !== undefined),
  );

  return definedVars;
};

export const getEnvironment = () => {
  const env = {
    ...getBuildEnvironment(),
    ...getRuntimeEnvironment(),
  };

  return env;
};
