import { useMemo } from 'react';

import { GrpcRestFetchTransport, useAuth0Interceptor } from '@deepup/api-utils';

import { scanClientApiV1 } from '@deepup/apis';
import { useQuery } from '@tanstack/react-query';
import { getEnvironment } from '@utils/getEnvironment';

export const useScanImages = ({ scanId }: { scanId?: string }) => {
  const { apiUnified } = getEnvironment();
  const authInterceptor = useAuth0Interceptor();

  const client = useMemo(() => {
    const transportRest = new GrpcRestFetchTransport({
      baseUrl: apiUnified,
      fetchInit: {
        credentials: 'include',
      },
      interceptors: [authInterceptor],
    });

    return new scanClientApiV1.ScanServiceClient(transportRest);
  }, [authInterceptor]);

  return useQuery({
    queryKey: ['getRawImages', { scanId }],
    queryFn: async () => {
      if (!scanId) {
        throw new Error('Scan ID is missing');
      }
      const imageResponse = await client.listScanImages({ scanId, resolution: 1 }).response;

      const sortedImages = imageResponse.images.sort((imgA, imgB) => {
        if (!(imgA.createdAt && imgB.createdAt)) {
          return -1;
        }

        return imgA.createdAt.nanos - imgB.createdAt.nanos;
      });

      return { images: sortedImages };
    },
    refetchOnWindowFocus: false,
  });
};
